const pinTerminals = [
    { value: 'P01', name: 'P01', code: '2435' },
    { value: 'P02', name: 'P02', code: '4480' },
    { value: 'P03', name: 'P03', code: '6670' },
    { value: 'P04', name: 'P04', code: '6671' },
    { value: 'P05', name: 'P05', code: '6672' },
    { value: 'P06', name: 'P06', code: '6673' },
    { value: 'P07', name: 'P07', code: '6674' },
    { value: 'P08', name: 'P08', code: '6675' },
    { value: 'P09', name: 'P09', code: '4139' },
    { value: 'P10', name: 'P10', code: '6677' },
    { value: 'P11', name: 'P11', code: '6678' },
    { value: 'P12', name: 'P12', code: '6679' },
    { value: 'P13', name: 'P13', code: '6680' },
    { value: 'P14', name: 'P14', code: '6681' },
    { value: 'P15', name: 'P15', code: '6682' },
    { value: 'P16', name: 'P16', code: '2426' },
    { value: 'P17', name: 'P17', code: '2417' },
    { value: 'P18', name: 'P18', code: '6685' },
    { value: 'P19', name: 'P19', code: '6686' },
    { value: 'P20', name: 'P20', code: '6687' },
    { value: 'P21', name: 'P21', code: '6688' },
    { value: 'P22', name: 'P22', code: '6689' },
    { value: 'P23', name: 'P23', code: '6690' },
    { value: 'P24', name: 'P24', code: '4136' },
    { value: 'P25', name: 'P25', code: '6692' },
    { value: 'P26', name: 'P26', code: '4137' },
    { value: 'P27', name: 'P27', code: '6694' },
    { value: 'P28', name: 'P28', code: '6695' },
    { value: 'P29', name: 'P29', code: '3294' },
    { value: 'P30', name: 'P30', code: '6697' },
    { value: 'P31', name: 'P31', code: '6698' },
    { value: 'P32', name: 'P32', code: '6699' },
    { value: 'P33', name: 'P33', code: '6700' },
    { value: 'P34', name: 'P34', code: '6701' },
    { value: 'P35', name: 'P35', code: '4478' },
    { value: 'P36', name: 'P36', code: '4135' },
    { value: 'P37', name: 'P37', code: '0615' },
    { value: 'P38', name: 'P38', code: '0618' },
    { value: 'P39', name: 'P39', code: '0619' },
    { value: 'P60', name: 'P60', code: '4138' },
    { value: 'P61', name: 'P61', code: '4134' },
    { value: 'P62', name: 'P62', code: '4128' },
    { value: 'P63', name: 'P63', code: '4129' },
    { value: 'P64', name: 'P64', code: '4133' },
    { value: 'P65', name: 'P65', code: '4130' },
    { value: 'P66', name: 'P66', code: '4131' },
    { value: 'P67', name: 'P67', code: '4132' },
    { value: 'PC1', name: 'PC1', code: '0621' },
    { value: 'PC2', name: 'PC2', code: '0620' },
    { value: 'PE1', name: 'PE1', code: '0622' },
]
module.exports = {
    pinTerminals,
}
