const templateUniqueRoles = [
    {
        value: null,
        name: 'Geen unieke rol',
    },
    {
        value: 'userFollowingStartedNotify',
        name: 'Gebruiker volgt order',
    },
    {
        value: 'userPasswordReset',
        name: 'Gebruiker wachtwoord vergeten',
    },
    {
        value: 'userEventRequestChanged',
        name: 'Gebruiker request gewijzigd',
    },
    {
        value: 'backoffice',
        name: 'Backoffice mail nieuw terugbelverzoek',
    },
    {
        value: 'newOrder',
        name: 'Klantmail nieuw order',
    },
    {
        value: 'newViewing',
        name: 'Klantmail nieuwe bezichtiging',
    },
    {
        value: 'newContact',
        name: 'Klantmail nieuw contact formulier',
    },
    {
        value: 'newCall',
        name: 'Klantmail nieuw terugbelverzoek',
    },
    {
        value: 'customerReview',
        name: 'Klantmail review',
    },
    {
        value: 'orderworkBooked',
        name: 'Management mail ingeboekt',
    },
    {
        value: 'orderworkOptionTomorrow',
        name: 'Opmo mail',
    },
    {
        value: 'customerPublicBooked',
        name: 'Klantportaal werkorder ingeboekt',
    },
    {
        value: 'customerPublicCancellation',
        name: 'Klantportaal werkorder geannuleerd',
    },
    {
        value: 'customerPublicEstimate',
        name: 'Klantportaal werkorder naar SZD',
    },
    {
        value: 'customerPublicM3Calculator',
        name: 'Klantportaal werkorder inboedel aangegeven',
    },
    {
        value: 'customerPublicViewingDate',
        name: 'Klantportaal bezichtigingsdatum gekozen',
    },
    // Automails
    {
        value: 'autoOptionExpired',
        name: 'Auto optie verlopen',
    },
    {
        value: 'autoOptionExpiresIn1Days',
        name: 'Auto optie verloopt binnen 24 uur',
    },
    {
        value: 'autoOptionExpiresIn2Days',
        name: 'Auto optie verloopt binnen 48 uur',
    },
    {
        value: 'responselessReminder5Days',
        name: 'Geen antwoord: na 5 dagen',
    },
    {
        value: 'responselessCancellation8Days',
        name: 'Geen antwoord: na 8 dagen naar annulering',
    },
    {
        value: 'estimateResponseless14Days',
        name: 'SZD: na 14 dagen naar geen antwoord',
    },
    {
        value: 'abandonedCart',
        name: 'AC: na 24 uur',
    },
    {
        value: 'abandonedCartReminderTwoDays',
        name: 'AC: na 48 uur',
    },
    {
        value: 'abandonedCartContinueLater',
        name: 'AC: continue later',
    },
    {
        value: 'rehousingIn1DaysInfo',
        name: 'Informatie verhuizing over 1 dag',
    },
    {
        value: 'rehousingIn4DaysInfo',
        name: 'Informatie verhuizing over 4 dagen',
    },
    {
        value: 'rehousingIn7DaysInfo',
        name: 'Informatie verhuizing over 7 dagen',
    },
    {
        value: 'rehousingIn14DaysInfo',
        name: 'Informatie verhuizing over 14 dagen',
    },
    {
        value: 'rehousingCompleted',
        name: 'Verhuizing afgerond',
    },
    {
        value: 'rehousingAfter3DaysInfo',
        name: 'Verhuizing 3 dagen geleden voltooid',
    },
    {
        value: 'rehousingAfter7DaysInfo',
        name: 'Verhuizing 7 dagen geleden voltooid',
    },
    {
        value: 'automailFirstBookedAfter2Hours',
        name: 'Verhuizing voor het eerst ingeboekt na 2 uur',
    },
]

module.exports = templateUniqueRoles
module.exports.enum = templateUniqueRoles.map((role) => role.value)
